











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.team_details_wrapper {
  .card .ct-label.ct-vertical.ct-start {
    width: 38px !important;
  }
  .for_line_graph_members_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 0;
    flex-wrap: wrap;
    .every_bullet_wrapper {
      display: flex;
      align-items: center;
      gap: 3px;
      margin-bottom: 20px;
      .bullet {
        background: #eee;
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }
      &.member_1 {
        .bullet {
          background-color: black;
        }
      }
      &.member_2 {
        .bullet {
          background-color: #fe9d52;
        }
      }
      &.member_3 {
        .bullet {
          background-color: #4198d7;
        }
      }
      &.member_4 {
        .bullet {
          background-color: brown;
        }
      }
      &.member_5 {
        .bullet {
          background-color: #7a61ba;
        }
      }
    }
  }
  .ct-series-a .ct-line,
  .ct-series-a .ct-point {
    stroke: black;
  }
  .ct-series-b .ct-line,
  .ct-series-b .ct-point {
    stroke: #fe9d52;
  }
  .ct-series-c .ct-line,
  .ct-series-c .ct-point {
    stroke: #4198d7;
  }
  .ct-series-d .ct-line,
  .ct-series-d .ct-point {
    stroke: brown;
  }
  .ct-series-e .ct-line,
  .ct-series-e .ct-point {
    stroke: #7a61ba;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .ct-label.ct-horizontal.ct-end {
      font-size: 11px !important;
    }
  }
  .is_chart_wrapper {
    .ct-chart-line {
      width: 90% !important;
    }
  }
}
